<template>
  <div class="report-utilization" v-if="mayIsee">
      <b-container style="max-width: 92% !important">
          <b-row class="mb-3">
              <div>
                  <b-input-group>
                      <HotelDatePicker class="item-date-picker-utilization" format="DD-MM-YYYY"
                          @confirm="customDateConfirmed()" v-model="customDate" type="date" :clearable="false" confirm
                          range placeholder="Selecione o período"></HotelDatePicker>
                      <b-button @click="exportData" type="button" class="btn btn-success" variant="success" size="sm" v-if="grids.default.loaded && grids.default.items.length" style="margin-left: 5px;">
                          <span>Download <i class="fas fa-cloud"></i></span>
                      </b-button>
                  </b-input-group>
              </div>
          </b-row>
          <b-row>
              <span class="selectgroup-button selectgroup-button-icon" v-if="grids.default.loaded" style="cursor: auto">Total de linhas: {{ grids.default.total }}</span>
              <b-table ref="myTable" :no-local-sorting='true' striped="striped" outlined="outlined" class="fontSize tableClicked bg-white" small="small"
                  hover="hover" responsive show-empty empty-text="Não foram encontrados registros."
                  :per-page="grids.default.perPage" v-if="grids.default.loaded" :items="grids.default.items"
                  :fields="grids.default.fields" @sort-changed='handleSort'>
              </b-table>
              <b-pagination @change="pagto" size="sm"
                  v-if="grids.default.loaded && grids.default.total > grids.default.perPage"
                  :total-rows="grids.default.total" v-model="grids.default.currentPage" :per-page="grids.default.perPage">
              </b-pagination>
          </b-row>
      </b-container>
  </div>
</template>
<script>
import Vue from "vue";
import VueHead from "vue-head";
import HotelDatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/pt-br";
import { utilizationService } from "../../components/common/services/utilization";
import DataExporter from "../../components/common/helpers/DataExporter";
import VTitle from "v-title";
import Moment from "moment";
import { VMoney } from "v-money";
import { extendMoment } from "moment-range";
import { func } from "@/functions";
const moment = extendMoment(Moment);
Vue.use(VTitle);
Vue.use(VueHead);
export default {
  mixins: [func],
  name: "report-forms",
  directives: {
      money: VMoney,
  },
  components: {
      HotelDatePicker,
  },
  head: {
      title: function () {
          return {
              inner: `TicketOffice | Admin`,
              separator: " | ",
              complement: "Relatórios - Formulário",
          };
      },
  },
  computed: {
      mayIsee() {
          return this.mayI("report-viewer", "report-utilization");
      },
  },
  created() { },
  async mounted() { 
    await Promise.all([this.selectionUpdated()]);
   },
  methods: {
      handleSort (sort) {
          this.sortColumn = sort.sortBy;
          this.sortDirection = sort.sortDesc;
          this.selectionUpdated();
      },
      pagto(to) {
          this.grids.default.currentPage = to;
          this.selectionUpdated();
      },
      async exportData() {
          if (!this.grids.default.total) return this.toastError("Não há dados para exportar");
          this.showWaitAboveAll();
          const dataExporter = new DataExporter({
              serviceToCall: utilizationService.list,
              optionsToService: this.formatOptionsToQuery(),
              itemsPerPage: 10000,
              totalPages: this.grids.default.total,
              columnsToExport: this.exportColumns,
              fileName: "relatorio_utilizacao_vale_ingresso_cupom_desconto",
          });
          await dataExporter.exportData();
          this.hideWaitAboveAll();
      },
      formatOptionsToQuery() {
          const options = {
              dateStart: moment(this.components.datepicker.startDate).format(
                  "YYYY-MM-DD"
              ),
              dateEnd: moment(this.components.datepicker.endDate).format(
                  "YYYY-MM-DD"
              ),
              loggedId: this.getLoggedId(),
              currentPage: this.grids.default.currentPage,
              perPage: this.grids.default.perPage,
              sortDirection: this.sortDirection ? "DESC" : "ASC",
              sortColumn: this.sortColumn,
          };
          if (!this.components.datepicker.endDate) {
              this.toastError("Selecione uma data final");
              return false
          }
          return options;
      },
      async customDateConfirmed() {
          if (this.customDate.length > 1 && this.customDate[0]) {
              if (moment(this.customDate[0]).isValid()) {
                  this.components.datepicker.startDate = moment(
                      this.customDate[0]
                  ).format("YYYY-MM-DD");
              }
              if (moment(this.customDate[1]).isValid()) {
                  this.components.datepicker.endDate = moment(
                      this.customDate[1]
                  ).format("YYYY-MM-DD");
              }
              await this.selectionUpdated();
          }
      },
      async selectionUpdated() {
          this.generalData = [];
          this.showWaitAboveAll();
          await new Promise((resolve) => setTimeout(resolve, 500));
          const options = this.formatOptionsToQuery();
          if (!options || !options.dateStart) return this.hideWaitAboveAll();
          this.processing = true;
          const response = await utilizationService.list(options);
          this.processing = false;
          this.grids.default.processing = false;
          this.hideWaitAboveAll();
          if (response && this.validateJSON(response)) {
              this.grids.default.loaded = true;
              this.grids.default.items = response;
              this.grids.default.currentPage = response.length == 0 ? 1 : response[1]["currentPage"];
              this.grids.default.total = response.length == 0 ? 0 : response[1]["totalCount"];
          }
      },
  },
  data() {
      return {
          processing: false,
          customDate: [
              new Date(),
              new Date()
          ],
          sortColumn: "_id",
          sortDirection: "DESC",
          exportColumns: {
            'Data do Pedido': 'dt_pedido_venda',
            'Nome do Evento': 'ds_evento',
            'Pedido': 'id_pedido_venda',
            'Código Utilizado': 'cd_promocional',
            'ID_PROMOCAO_CONTROLE': 'CodTipPromocao_controle',
            'Base': 'ds_nome_base_sql',
            'Cliente': 'cliente_nome',
            'DDD Celular': 'ds_ddd_celular',
            'Celular': 'ds_celular',
            'Cidade': 'ds_cidade',
            'Email': 'cd_email_login',
            'Inicio da Promoção': 'dt_inicio_promocao',
            'Fim da Promoção': 'dt_fim_promocao',
            'Tipo Promoção': 'CodTipPromocao',
            'Promoção': 'ds_promocao_controle',
            'Bilhete': 'ds_tipo_bilhete',
            'Valor Unitário do Ingresso': 'vl_unitario',
            'Quantidade de Ingressos': 'qt_ingressos',
            'Total do pedido (sem desconto)': 'vl_total_ingressos',
            'Total Pago': 'vl_total_pedido_venda',
            'Descontos totais': 'valor_desconto_total',
            'Desconto em %': 'perc_desconto_vr_normal',
            'Desconto Fixo': 'vl_preco_fixo',
            'Taxa de Conveniência': 'vl_total_taxa_conveniencia',
            'Desconto Cupom': 'valor_desconto_total_cupom',
            'Desconto Vale Ingresso': 'valor_desconto_total_cupom_ingresso',
            'Taxa de Conveniência por Ingresso': 'vl_taxa_conveniencia',
            'Tipo de Bilhete': 'tickettype',
            'Portal': 'uniquename_partner',
            'Empresa': 'empresa',
            'CNPJ': 'empresa_cnpj',

        },
          components: {
              datepicker: {
                  id: 1,
                  format: "DD/MM/YYYY",
                  minNights: 0,
                  maxNights: 0,
                  hoveringTooltip: true,
                  displayClearButton: true,
                  startDate: new Date(),
                  endDate: new Date(),
                  ptBr: {
                      night: "Dia",
                      nights: "Dias",
                      "day-names": ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
                      "check-in": "Início",
                      "check-out": "Fim",
                      "month-names": [
                          "Janeiro",
                          "Fevereiro",
                          "Março",
                          "Abril",
                          "Maio",
                          "Junho",
                          "Julho",
                          "Agosto",
                          "Setembro",
                          "Outubro",
                          "Novembro",
                          "Dezembro",
                      ],
                  },
              },
          },
          grids: {
              default: {
                  processing: false,
                  loaded: false,
                  total: 0,
                  currentPage: 1,
                  perPage: 20,
                  items: [],
                  fields: {
                    dt_pedido_venda: { label: 'Data do Pedido', sortable: false },  // Quem usou
                    cliente_nome: { label: 'Cliente', sortable: false },  // Quem usou
                    id_pedido_venda: { label: 'Pedido', sortable: false },  // Quem usou
                    cd_promocional: { label: 'Código Usado', sortable: false },  // Quem usou
                    ds_evento: { label: 'Nome do Evento', sortable: false },  // Onde usou
                    vl_unitario: { label: 'Valor Unitário do Ingresso', sortable: false },  // Quanto deveria ter pago
                    qt_ingressos: { label: 'Quantidade de Ingressos', sortable: false, showTotal: true },  // Quanto deveria ter pago
                    vl_total_ingressos: { label: 'Total do pedido (sem desconto)', sortable: false, showTotal: true },  // Quanto deveria ter pago
                    vl_total_pedido_venda: { label: 'Total Pago', sortable: false, showTotal: true },  // Quanto pagou
                    valor_desconto_total: { label: 'Descontos Totais', sortable: false, showTotal: true },  // Quanto demos de desconto
                    perc_desconto_vr_normal: { label: 'Desconto em %', sortable: false },  // Quanto demos de desconto
                    vl_preco_fixo: { label: 'Desconto Fixo', sortable: false },  // Quanto demos de desconto
                  },
              },
          },
      };
  },
};
</script>
<style lang="scss" scoped>
.report-utilization {

  .mx-input:focus,
  .mx-input:focus-visible,
  .mx-input:hover,
  .utilization-selects:focus,
  .utilization-selects:focus-visible {
      outline: none;
      border: 1px solid #ccc !important;
  }

  .mx-input,
  .utilization-selects {
      cursor: pointer;
  }

  .mx-input::placeholder {
      color: #555;
  }

  .item-date-picker-utilization {

      .mx-input-wrapper,
      .mx-input {
          height: 100%;
      }
  }

  .utilization-selects {
      border: 1px solid #ccc;
      color: #555;
      width: 270px;
      height: 34px;
      border-radius: 4px;
  }
}

.report-utilization th {
  background: white;
  position: sticky;
  top: 37px;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
  font-weight: bold;
  color: #504848;
}

.report-utilization .fixed-top-utilization {
  background: white;
  position: sticky;
  top: 0px;
  z-index: 999 !important;
  box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.4);
}

.report-utilization tfoot td {
  background: white;
  position: sticky;
  bottom: 0;
}

.fixedTableLabel {
  max-width: 313px;
  text-overflow: ellipsis;
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
}
</style>