import Vue from 'vue';
import VueResource from "vue-resource";
import config from '@/config';

Vue.use(VueResource);

config.setapikey();

export const utilizationService = {
    list
}

function list({ loggedId, currentPage, perPage, dateStart, dateEnd, sortColumn, sortDirection }) {
    let url = config.api + `/v1/admin/report/utilization/list?loggedId=${loggedId}&dtInicial=${dateStart}&dtFinal=${dateEnd}&sortColumn=${sortColumn}&sortDirection=${sortDirection}`;
    url = config.system.applyPagination(url, currentPage, perPage);
  
    const options = {
      responseType: 'json',
    };
  
    return Vue.http.get(url, options)
      .then(response => {
        if (response.data.rows && response.data.totals) {
          response.data.rows.unshift(response.data.totals);
        }
        return response.data.rows;
      })
      .catch(error => {
        console.error('Error downloading XLSX file or fetching data:', error);
        throw error;
      });
  }